button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.settings-screen-time {
  .explanatory-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: justify;

    margin-bottom: 12px;
    font-size: 0.9rem;
    color: #333;
    line-height: 1.4;
    max-width: 500px;
  }

  .child-screen-time {

    .child-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: transparent;
      border: none;
      border-radius: 4px;

      .child-name {
        flex-grow: 1;
        text-align: left;
        font-size: 0.9rem;
      }
    }

    .screen-time-schedule {
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
      background-color: #f9f9f9;
      position: relative;

      .day-names {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .day-name {
          flex: 1;
          text-align: center;
          font-weight: bold;
          color: #333;
          font-size: 0.8rem;
        }
      }

      .daily-limits {
        display: flex;
        justify-content: space-between;

        .day-limit {
          flex: 1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin: 0;
          padding: 0;

          .adjust-button {
            width: 36px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            margin: 0;
            padding: 0;
            background-color: transparent;
            border: none;
            font-size: 1.5em;
            cursor: pointer;
            color: #823d90;
          }

          .limit-circle {
            width: 36px;
            height: 30px;
            border-radius: 8px;
            background-color: #e0e0e0;
            color: #333;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 0.8rem;
            border: 1px solid #ccc;
            box-shadow: none;
            font-weight: normal;
          }

        }
      }

    }
  }

  .preset-settings {
    display: flex;
    flex-direction: column;

    .preset-text {
      display: flex;
      justify-content: flex-start;

      font-size: 0.85rem;

      margin-bottom: 4px;
    }

    .preset-buttons {
      display: flex;
      justify-content: center;
      margin-bottom: 16px;

      .preset-button {
        padding: 4px 10px;
        margin: 4px 8px;
        font-size: 0.8rem;
        background: linear-gradient(135deg, #823d90, #b06cc9);
        color: white;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }
    }
  }
}
