.login-page {
  flex: 1;
  width: 100%;

  .action-label {
    font-size: 0.8rem;
    color: #007BFF;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      color: #0056b3;
      text-decoration: underline;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 435px;
    max-width: 435px;

    margin: 10vh auto;
    padding: 30px 15px;

    border-radius: 16px;

    font-size: 16px;
    font-weight: 400;
    line-height: 1.4em;

    background-color: #e4e4e4;
  }

  &__container-inputs {
    width: 100%;
  }

  &__inputs {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__input {
    width: 97%;

    margin: 8px 0;
    padding: 8px 4px;

    border: 1px solid #707070;
    border-radius: 8px;

    line-height: 1.4em;
    font-size: 16px;
    font-weight: 400;

    &--error {
      border: 2px solid rgb(255, 99, 99) !important;
    }
  }

  &__inputs--password {
    .login-page__input-container {
      position: relative;
      width: 100%;

      .password-toggle-icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

  }

  &__error-message {
    color: rgb(219, 0, 0);
    margin-top: 24px;
  }

  &__submit-button {
    width: 100%;

    margin: 24px auto 0 auto;
    padding: 12px;

    border: none;
    border-radius: 8px;

    color: white;
    font-size: 1rem;

    cursor: pointer;
    transition: background-color 0.3s ease;

    background-color: var(--primary-color);

    &:hover {
      background-color: var(--primary-color);
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }

  &__submit-message {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 12px;
    padding: 8px;

    border: 2px white solid;
    border-radius: 5px;

    color: black;

    background-color: white;

    overflow: auto;

    &--error {
      border: 2px rgb(139, 0, 0) solid;
      background-color: rgb(139, 0, 0);
    }
  }

  &__no-account {
    margin: 24px auto 0;
    text-align: center;
  }

  @media (max-width: 980px) {
    &__container {
      width: calc(100% - 24px);
      max-width: 288px;

      margin: 7vh auto;
    }
  }
}
