.video-section {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 0 2rem 0;

  justify-self: center;
  max-width: 1286px;
}

.responsive-video {
  width: 90%;
  height: auto;
  aspect-ratio: 16 / 9;

  @media (min-width: 1024px) {
    width: 80%;
  }

  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}
