.for-who {
  padding: 0;
  max-width: 1024px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  min-height: 400px;
  color: white;
  
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: auto;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100%;
    z-index: -2;
  }

  &::before {
    left: -100vw;
    background-color: var(--analytics_orange);
  }

  &::after {
    right: -100vw;
    background-color: var(--analytics-violet);
  }

  .title {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: white;
    text-align: center;
    padding: 3rem 1.5rem 0;
  }

  .overlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: left;
    background: rgba(0, 0, 0, 0.1);
    padding: 2rem;
    border-radius: 25px;
    backdrop-filter: blur(4px);

    .bullets {
      list-style: none;
      padding: 0;

      li {
        font-size: 1.25rem;
        margin-bottom: 1rem;
        position: relative;
        padding-left: 1.5rem;

        &::before {
          content: "✔";
          position: absolute;
          left: 0;
          color: var(--analytics_orange);
        }
      }
    }

  }

  @media (min-width: 768px) {
    min-height: 500px;

    .title {
      font-size: 2.5rem;
    }
  }

  @media (min-width: 1024px) {
    .title {
      font-size: 2.8rem;
    }
  }
}
