.action-section {
  // padding: 0 1rem;
  max-width: 1024px;
  margin: 1rem auto;
  cursor: pointer;

  .overlay {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(8px);
    border-radius: 40px;
    padding: 1.5rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2rem;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }

  .overlay:hover {
    transform: scale(1.02);
  }

  .action-image {
    height: 45px;
    width: auto;
  }

  .action-text {
    flex: 1;
    color: white;
    font-size: 1.2rem;
    font-weight: 600;
    text-align: left;
  }

  .arrow {
    color: white;
    height: 32px;
    width: 32px;
  }

  @media (min-width: 768px) {
    .action-text {
      font-size: 1.6rem;
    }

    .action-image {
      height: 60px;
    }
  }
}
