@import '../../../common/swiper-arrows.scss';

.whywytopia-section {
  padding: 0 1rem;
  margin: 2rem auto;

  max-width: 1024px;
  color: white;

  .overlay {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(8px);
    border-radius: 40px;
    padding-top: 2rem;
    padding-bottom: 2rem;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow: hidden;
  }

  .title {
    font-size: 2rem;
    text-align: center;
    padding: 0 2rem;
    margin: 0;
    color: var(--text-color-white);
  }

  .carousel {
    width: 100%;
    position: relative;

    .swiper-wrapper {
      width: 100% !important;
      margin-bottom: 2rem;
    }

    .swiper-slide {
      width: 100% !important;
      display: flex;
      justify-content: center;
    }

    .argument-slide {
      width: 100%;
      max-width: 100%;
      display: flex;
      align-items: center;
      gap: 1rem;
      padding: 0 2rem;
      margin: 0 auto;
    }

    .argument-image {
      width: 80px;
      height: 80px;
      object-fit: contain;
      flex-shrink: 0;
    }

    .argument-text {
      flex: 1;
      font-size: 1rem;
      text-align: justify;
    }

    .argument-text p {
      margin: 0;
      line-height: 1.5;
      text-align: left;
    }

    .argument-text p + p {
      margin-top: 0.75rem;
    }

    .swiper-pagination {
      position: absolute;
      bottom: 0.5rem;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      z-index: 5;
    }

    .swiper-pagination-bullet {
      background-color: white;
      opacity: 0.4;
    }

    .swiper-pagination-bullet-active {
      background-color: var(--analytics-violet);
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    .title {
      font-size: 2.5rem;
    }

    .carousel .argument-text {
      font-size: 1.2rem;
    }

    .carousel .argument-image {
      width: 100px;
      height: 100px;
    }
  }
}
