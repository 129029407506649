.app-details {
  padding: 4rem 2rem;
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
  color: white;

  .images-row {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: nowrap;
    flex-direction: row;
    margin-bottom: 2rem;

    img {
      width: auto;
      max-width: 300px;
      height: auto;
      object-fit: contain;
      border-radius: 12px;
    }
  }

  .subtitle {
    font-size: 1.8rem;
    margin-bottom: 3rem;
    color: var(--text-color-white);
    white-space: nowrap;
    text-align: center;
  }

  .description {
    font-size: 1.1rem;
    line-height: 1.7;
    max-width: 960px;
    margin: auto;
    text-align: justify;

  }

  @media (min-width: 768px) {
    .subtitle {
      font-size: 2rem;
    }

    .description {
      font-size: 1.3rem;
    }
  }

  @media (min-width: 1024px) {
    .subtitle {
      font-size: 2.2rem;
    }

    .description {
      font-size: 1.4rem;
    }
  }
}
