.privacy-section {
  padding: 0 1rem;
  max-width: 1024px;
  margin: 1rem auto 0;

  .overlay {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(8px);
    border-radius: 40px;
    padding: 2rem;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  }

  .content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }

  .left-image {
    width: 50%;
    height: auto;
    object-fit: contain;
    flex-shrink: 0;
    margin-bottom: 1rem;
  }

  .text-content {
    width: 100%;
    text-align: center;
  }

  .title {
    color: white;
    font-size: 2rem;
    margin: 0 0 1rem 0;
    text-align: center;
  }

  .description {
    color: white;
    font-size: 1rem;
    text-align: justify;
    line-height: 1.6;
    margin: 0;
  }

  @media (min-width: 768px) {
    .content-container {
      flex-direction: row;
      align-items: flex-start;
    }

    .left-image {
      width: 20%;
      margin-bottom: 0;
    }

    .text-content {
      width: auto;
      text-align: left;
    }

    .title {
      font-size: 2.5rem;
      text-align: left;
    }
  }

  @media (min-width: 1024px) {
    .title {
      font-size: 2.8rem;
    }
  }
}
