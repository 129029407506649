/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

:root {
    --primary-color: #3ababb;
    --secondary-color: #f39346;
    --tertiary-color: #f39446b6;
    --background-color: #ffffff;
    --text-color-white: #ffffff;
    --text-color-black: #000000;
    --border-color: black;
    --button-background: white;
    --analytics-violet: #823d90;
    --analytics_orange: #f49447;
    --analytics-card-background: #f0f8fb;
    --analytics_skill1: #018cd5;
    --analytics_skill2: #de2781;
    --analytics_skill3: #f19700;
}


html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

h2 {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
}

h3 {
    font-size: 0.9rem;
    font-weight: bold;
    margin-bottom: 0.3rem;
}