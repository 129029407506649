.action-sections-container {
  display: flex;
  flex-direction: column;
  max-width: 1024px;
  margin: 0 auto;
  padding: 0 1rem;

  .action-section {
    width: 100%;
    margin-bottom: 1rem;

    &:first-child {
      margin-bottom: 0;
    }
  }
  
  /* In desktop view, side by side */
  @media (min-width: 769px) {
    flex-direction: row;
    
    gap: 1rem;

    .action-section {
      flex: 1;
      margin: 1rem 0;
      max-width: none;
    }
  }
}
