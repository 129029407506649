.how-to-convince {
  padding: 3rem 1.5rem 0;
  max-width: 1024px;
  margin: 0 auto;
  background-color: white;
  color: black;
  justify-items: center;

  .section-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    max-width: 23ch;
    color: var(--primary-color);
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .bullet-points {
      list-style: disc;
      padding-left: 1.5rem;
      text-align: left;
      font-size: 1.25rem;
      line-height: 1.6;

      li {
        margin-bottom: 0.75rem;
      }
    }

    .child-image-container {
      position: relative;
      margin-bottom: -2px;
      align-self: center;

      img {
        width: 200px;
        max-width: 60vw;
        object-fit: contain;
      }
    }
  }

  @media (min-width: 768px) {
    .section-title {
      font-size: 2.5rem;
    }

    .content {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;

      .bullet-points {
        font-size: 1.25rem;
      }

      .child-image-container img {
        width: 300px;
        position: relative;
        right: -30%;
      }
    }
  }

  @media (min-width: 1024px) {
    .section-title {
      font-size: 2.8rem;
    }

    .content .bullet-points {
      font-size: 1.25rem;
    }
  }
}
