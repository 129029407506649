.parent-intro-section {
  padding: 4rem 2rem 0;
  max-width: 1286px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;

  .overlay {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(8px);
    border-radius: 50px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 2rem;
    width: 100%;
    max-width: 1024px;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.1);
  }

  .logo {
    width: auto;
    max-width: 100%;
    height: auto;
  }

  .smartphone {
    width: auto;
    max-width: 130%;
    height: auto;
    object-fit: contain;
  }

  .headline {
    font-size: 2rem;
    color: white;
    max-width: 30ch;

    .highlight {
      color: var(--analytics_orange); // ou #ff5e00 selon ta charte
    }
  }

  @media (min-width: 768px) {
    .headline {
      font-size: 2.5rem;
    }

    .logo {
      width: auto;
      max-width: 80%;
      height: auto;
    }
    
    .smartphone {
      max-width: 100%;
    }
    
  }

  @media (min-width: 1024px) {
    .headline {
      font-size: 3rem;
    }
  }
}
