.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: none;
  border: none;
  width: 28px;
  height: 56px;
  cursor: pointer;
}

// Style de base pour les flèches (triangle plein)
.swiper-button-prev::after,
.swiper-button-next::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
}

.swiper-button-prev::after {
  border-width: 17px 28px 17px 0;
  left: -30%;
}

.swiper-button-next::after {
  border-width: 17px 0 17px 28px;
  right: -30%;
}

// WhatIsWytopia - Orange
.what-is-wytopia .carousel {
  .swiper-button-prev::after,
  .swiper-button-next::after {
    border-color: transparent var(--analytics_orange) transparent transparent;
  }
  
  .swiper-button-next::after {
    border-color: transparent transparent transparent var(--analytics_orange);
  }
}

// ChildFeedbacks - Violet
.child-feedbacks .carousel {
  .swiper-button-prev::after,
  .swiper-button-next::after {
    border-color: transparent var(--analytics-violet) transparent transparent;
  }
  
  .swiper-button-next::after {
    border-color: transparent transparent transparent var(--analytics-violet);
  }
}

// WhyWytopiaSection - Cyan
.whywytopia-section .carousel {
  .swiper-button-prev::after,
  .swiper-button-next::after {
    border-color: transparent var(--primary-color) transparent transparent;
  }
  
  .swiper-button-next::after {
    border-color: transparent transparent transparent var(--primary-color);
  }
}

// ParentFeedbacks - Orange
.parent-feedbacks .carousel {
  .swiper-button-prev::after,
  .swiper-button-next::after {
    border-color: transparent var(--analytics_orange) transparent transparent;
  }
  
  .swiper-button-next::after {
    border-color: transparent transparent transparent var(--analytics_orange);
  }
}

// HowToContribute - Skill2 (bleu)
.how-to-contribute .carousel {
  .swiper-button-prev::after,
  .swiper-button-next::after {
    border-color: transparent var(--analytics_skill2) transparent transparent;
  }
  
  .swiper-button-next::after {
    border-color: transparent transparent transparent var(--analytics_skill2);
  }
} 