@import '../../../common/swiper-arrows.scss';

.what-is-wytopia {
  padding: 3rem 1.5rem 0;
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
  color: white;

  .section-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: var(--text-color-white);
    white-space: nowrap;
  }

  .carousel {
    width: 100%;
    position: relative;
    padding-bottom: 2rem;

    .carousel-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.5rem;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
        border-radius: 12px;
      }

      .carousel-text {
        list-style: disc;
        padding-left: 2rem;
        margin: 0 auto;
        max-width: 768px;
        text-align: left;

        li {
          margin-bottom: 0.5rem;
          font-size: 1.25rem;
        }
      }
    }

    .swiper-pagination {
      position: absolute;
      bottom: 0.5rem;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      z-index: 5;
    }

    .swiper-pagination-bullet {
      background-color: white;
      opacity: 0.4;
    }

    .swiper-pagination-bullet-active {
      background-color: var(--analytics_orange);
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    .section-title {
      font-size: 2.5rem;
    }

    .carousel-text li {
      font-size: 1.3rem;
    }
    
    .carousel {
      width: 80%;
      padding-bottom: 5rem;
    }
  }

  @media (min-width: 1024px) {
    .section-title {
      font-size: 2.8rem;
    }

    .carousel-text li {
      font-size: 1.5rem;
    }
  }
}
