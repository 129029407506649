// Variables SCSS
$breakpoints: (
  'small': 576px,
  'medium': 768px,
  'large': 992px,
  'xlarge': 1200px
);

// Mixins pour la responsivité
@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }
}

// Optimisations pour les performances
.content-page {
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
  width: 100%;
  overflow-x: hidden;

  .child-zone,
  .parent-zone {
    width: 100%;
  }

  .scene-break-container {
    width: 100%;
    overflow: hidden;

    .scene-break-image {
      width: 100%;
      height: auto;
      display: block;
      object-fit: cover;
    }
  }
}