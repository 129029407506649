.css-background-section {
  position: relative;
  width: 100%;
  background-color: #000;
  overflow: hidden;
  z-index: 0;

  .bg-bubble {
    position: absolute;
    border-radius: 50%;
    filter: blur(120px);
    opacity: 0.4;
    z-index: 0;
    pointer-events: none; // important pour pas bloquer le scroll/clicks
  }

  > * {
    position: relative;
    z-index: 1;
  }
}
