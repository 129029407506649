.hero-section {
  padding: 0 1rem 0;
  max-width: 1286px;
  margin: 0 auto;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  justify-self: center;

  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.75rem;
    color: white;
    padding: 1rem;
    line-height: normal;

    h1 {
      position: relative;
      z-index: 2;
      font-size: 3rem;
      margin: 0;
      max-width: 10ch;
    }

    h2 {
      font-size: 1.1rem;
      margin: 0;
      color: white;
      text-align: start;
      line-height: 1.5;
      max-width: 35ch;
    }

    .hero-buttons {
      display: flex;
      justify-content: center;
      width: 100%;
      gap: 1rem;
      margin-top: 1rem;

      a {
        flex: 1;
        max-width: 200px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  .hero-image-container {
    position: relative;
    z-index: 1;
    width: 100%;
    
    .hero-image {
      position: relative;
      bottom: -2rem;
      z-index: 1;
      width: 100%;
      height: auto;
      display: block;
    }
    
    .screen-overlays {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      pointer-events: none;
      
      .overlay-container {
        position: relative;
        width: 100%;
        height: 100%;
        bottom: -2rem;
      }
      
      .left-screen-overlay,
      .right-screen-overlay {
        position: absolute;
        width: 100%;
        height: auto;
        left: 0;
        object-fit: contain;
        transition: opacity 0.5s ease-in-out;
        mask-image: linear-gradient(to bottom, black 0%, black 100%);
        -webkit-mask-image: linear-gradient(to bottom, black 0%, black 100%);
      }
      
      .fade-in {
        opacity: 1;
      }
      
      .fade-out {
        opacity: 0;
      }
    }
  }
}

@media (min-width: 768px) {
  .hero-section {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem 6rem 0;

    .hero-content {
      align-items: flex-start;
      text-align: left;
      padding: 0;
      gap: 1rem;

      h1 {
        font-size: 3rem;
      }

      h2 {
        font-size: 1.5rem;
      }

      .hero-buttons {
        justify-content: flex-start;
        gap: 1.5rem;

        a {
          flex: unset;
          max-width: none;
        }

        img {
          height: 128px;
          width: auto;
        }
      }
    }

    .hero-image-container {
      flex: 1;
      max-width: 500px;
      position: relative;
      
      .hero-image {
        max-height: none;
        bottom: 1rem;
        width: 100%;
      }
      
      .screen-overlays {
        top: 0;
        
        .overlay-container {
          bottom: 1rem;
        }

        .left-screen-overlay,
        .right-screen-overlay {
          top: 0;
        }
      }
    }
  }
}
