.advanced-progress-bar {
  width: 100%;

  margin: 24px 0 12px 0;

  .advanced-progress-bar__track {
    position: relative;

    width: 100%;

    border-radius: 50px;
    background-color: #e0e0de;

    &--small {
      height: 5px;
    }

    &--medium {
      height: 10px;
    }

    &--big {
      height: 15px;
    }

    .advanced-progress-bar__filler {
      height: 100%;

      border-radius: inherit;
      background-color: #823d90;

      transition: width 1s ease-in-out;
    }
  }

  .advanced-progress-bar__info-container {
    &--top {
      display: flex;
      justify-content: space-between;

      margin-bottom: 8px;
    }

    &--bottom {
      display: flex;
      justify-content: space-between;

      margin-top: 8px;
    }
  }

  .advanced-progress-bar__info {
    display: flex;
    align-items: center;
    gap: 5px;

    font-size: 0.8rem;
    font-weight: bold;

    img {
      width: 16px;
      height: 16px;
    }

    &--left {
      justify-content: flex-start;
    }

    &--right {
      justify-content: flex-end;
    }
  }

  &__average-text {
    position: absolute;
    bottom: 100%;
    transform: translateX(-25px) translateY(-5px);

    max-width: 50px;
    width: 100%;

    padding: 2px 4px;

    font-size: 0.6rem;
    font-weight: 600;
    color: black;

    border-radius: 4px;

    z-index: 11;
  }

  &__average-line {
    position: absolute;
    top: -40%;

    width: 2px;
    height: 180%;

    background-color: black;

    z-index: 10;
  }
}
