.recommendation-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #00000008;

  &__head {
    border: none;
  }

  &__foot {
    border: none;
    text-align: center;
    padding-top: 8px;
  }

  &__row {
    border-bottom: 1px solid #ccc;

    td {
      border-right: 1px solid #ccc;
      padding: 0px;

      &:last-child {
        border-right: none;
      }
    }
  }

  &__header {
    border: none;
  }

  &__foot {
    td {
      border: none;
    }
  }

  &__header {
    &--icon {
      width: 16px;
      height: 16px;
    }
  }

  &__header,
  &__cell {
    text-align: center;
    vertical-align: middle;
  }

  &__cell {
    &--star {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      height: 100%;

      .star-container {
        position: relative;
        display: inline-block;

        width: 32px;

        height: 100%;

        .star-image {
          width: 100%;
          height: 100%;
        }

        .star-number {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);

          color: white;
          font-size: 0.8rem;
        }
      }
    }

    &--activity {
      .activity-info-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .category-icon {
          padding: 0 4px;
          width: 24px;
          height: 24px;
        }

        &__title {
          display: flex;
          flex-direction: column;

          font-size: 0.8rem;
          font-weight: bold;
        }

        &__category {
          font-size: 0.65rem;
          color: #666;
        }
      }
    }
  }

  .icon-button {
    background: none;
    border: none;
    cursor: pointer;

    &__image {
      width: 24px;
      height: 24px;
    }
  }
}
