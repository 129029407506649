@import '../../../common/swiper-arrows.scss';

.how-to-contribute {
  background-color: var(--analytics_skill2);
  padding: 2rem 1rem;
  max-width: 1024px;
  margin: 0 auto;
  
  .contribute-header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
    
    .heart-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 80px;
      height: 80px;
      position: relative;
      
      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
      }
    }
    
    .header-title {
      color: white;
      font-size: 2rem;
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  .desktop-view {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    padding: 2rem 0;
  }

  .contribute-card {
    background: white;
    color: black;
    padding: 2rem;
    border-radius: 12px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    width: calc(33.33% - 14px);
    min-height: 500px;
    
    .slide-title {
      font-size: 1.25rem;
      margin-bottom: 1rem;
      color: var(--text-color-black);
    }
    
    .points {
      list-style: disc;
      padding-left: 1.2rem;
      line-height: 1.6;
      font-size: 1rem;
      flex-grow: 1;

      li {
        margin-bottom: 0.75rem;

        em {
          font-style: italic;
        }
      }
    }
    
    .cta-block {
      margin-top: auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .community-icon {
        width: 50px;
        height: 50px;
        background-color: var(--analytics-violet);
        border-radius: 50%;
        margin-bottom: 0.5rem;
        position: relative;
        
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }

      .cta {
        font-weight: bold;
        margin-bottom: 0.5rem;
        color: var(--analytics-violet);
      }

      .email {
        color: var(--analytics-violet);
        font-weight: bold;
        text-decoration: underline;

        &:visited {
          color: var(--analytics-violet);
        }
      }
    }
  }

  .section-title {
    color: white;
    text-align: center;
    font-size: 2rem;
    margin-bottom: 2rem;
  }

  .carousel {
    width: 100%;
    position: relative;
    padding-bottom: 2rem;

    .swiper-slide {
      display: flex;
      justify-content: center;
    }

    .swiper-pagination {
      position: absolute;
      bottom: 0.5rem;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      z-index: 5;
    }

    .swiper-pagination-bullet {
      background-color: white;
      opacity: 0.4;
    }

    .swiper-pagination-bullet-active {
      background-color: var(--analytics-violet);
      opacity: 1;
    }
  }

  .contribute-slide {
    background: white;
    color: black;
    padding: 2rem;
    border-radius: 24px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    max-width: 1024px;

    .slide-title {
      color: var(--analytics-violet);
      font-size: 2rem;
      text-align: center;
    }

    .top {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      .illustration {
        width: 80px;
      }
    }

    .points {
      list-style: disc;
      padding-left: 1.2rem;
      line-height: 1.6;
      font-size: 1rem;

      li {
        margin-bottom: 0.75rem;

        em {
          font-style: italic;
        }
      }
    }

    .cta {
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;
      cursor: pointer;
      font-weight: bold;
      font-size: 1.5rem;
      text-align: center;

      img {
        width: auto;
        height: 64px;
      }

      span {
        color: var(--analytics-violet);
        text-decoration: underline;

        &:visited {
          color: var(--analytics-violet);
        }
      }
    }

    .cta-block {
      margin-top: auto;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      .community-icon {
        width: 50px;
        height: 50px;
        background-color: var(--analytics-violet);
        border-radius: 50%;
        margin-bottom: 0.5rem;
        position: relative;
        
        &:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 30px;
          height: 30px;
          background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3E%3Cpath d='M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain;
        }
      }

      .cta {
        font-weight: bold;
        margin-bottom: 0.5rem;
        color: var(--analytics-violet);
      }

      .email {
        color: var(--analytics-violet);
        font-weight: bold;
        text-decoration: underline;

        &:visited {
          color: var(--analytics-violet);
        }
      }
    }
  }

  @media (min-width: 768px) {
    .contribute-header {
      .header-title {
        font-size: 2.5rem;
      }
    }
    .section-title {
      font-size: 2.5rem;
    }
    .contribute-slide {
      .slide-title {
        font-size: 2.5rem;
      }
      .top {
        flex-direction: row;
        text-align: left;

        .illustration {
          width: 120px;
        }
      }
    }
    .desktop-view {
      .contribute-card {
        .slide-title {
          font-size: 1.8rem;
        }
        
        .points {
          flex-grow: 1;
        }
        
        .cta-block {
          margin-top: auto;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .contribute-header {
      .header-title {
        font-size: 2.8rem;
      }
    }
    .section-title {
      font-size: 2.8rem;
    }
    .contribute-slide {
      .slide-title {
        font-size: 2.8rem;
      }
    }
  }
}
