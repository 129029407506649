.App {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  min-height: 100vh;
  width: 100%;

  background: var(--background-color);

  font-family: "Helvetica Rounded", "Helvetica", "Quicksand", "Helvetica Rounded", "Helvetica", "NotoSans", "Roboto", -apple-system, BlinkMacSystemFont,
  "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
  "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;600;700&display=swap');

@font-face {
  font-family: "GrilCB";
  src: url("./fonts/GrilCheese/GrilCB.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* NotoSans - Black */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* NotoSans - Black Italic */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

/* NotoSans - Bold */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* NotoSans - Bold Italic */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

/* NotoSans - Extra Bold */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* NotoSans - Extra Bold Italic */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic;
}

/* NotoSans - Extra Light */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

/* NotoSans - Extra Light Italic */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

/* NotoSans - Italic */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* NotoSans - Light */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* NotoSans - Light Italic */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

/* NotoSans - Medium */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* NotoSans - Medium Italic */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

/* NotoSans - SemiBold */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* NotoSans - SemiBold Italic */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

/* NotoSans - Thin */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

/* NotoSans - Thin Italic */
@font-face {
  font-family: "NotoSans";
  src: url("./fonts/NotoSans/NotoSans-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Helvetica";
  src: url("./fonts/Helvetica/Helvetica-LT.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url("./fonts/Helvetica/Helvetica-LT-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url("./fonts/Helvetica/Helvetica-LT-Bold-Oblique.ttf") format("truetype");
  font-weight: bold;
  font-style: oblique;
}

@font-face {
  font-family: "Helvetica";
  src: url("./fonts/Helvetica/Helvetica-LT-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica";
  src: url("./fonts/Helvetica/Helvetica-LT-Light-Oblique.ttf") format("truetype");
  font-weight: 300;
  font-style: oblique;
}

@font-face {
  font-family: "Helvetica";
  src: url("./fonts/Helvetica/Helvetica-LT-Oblique.ttf") format("truetype");
  font-weight: normal;
  font-style: oblique;
}

@font-face {
  font-family: "Helvetica Rounded";
  src: url("./fonts/Helvetica/Helvetica-Rounded-LT-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Helvetica Rounded";
  src: url("./fonts/Helvetica/Helvetica-Rounded-LT-Bold-Oblique.ttf") format("truetype");
  font-weight: bold;
  font-style: oblique;
}