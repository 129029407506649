@import '../../../common/swiper-arrows.scss';

.child-feedbacks {
  padding: 3rem 1.5rem 0;
  max-width: 1024px;
  margin: 0 auto;
  text-align: center;
  color: white;

  .section-title {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: var(--text-color-white);
  }

  .carousel {
    width: 100%;
    position: relative;
    padding-bottom: 2rem;

    .swiper-slide {
      display: flex;
      justify-content: center;
    }

    .feedback-card {
      background: white;
      color: black;
      border-radius: 12px;
      padding: 1.5rem;
      width: 80%;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      box-sizing: border-box;

      .header {
        display: flex;
        align-items: center;
        gap: 1rem;

        .avatar {
          width: 128px;
          height: 128px;
          border-radius: 50%;
          object-fit: cover;
        }

        .info {
          flex: 1;
          text-align: left;

          h3 {
            margin: 0;
            font-size: 1.2rem;
            font-weight: bold;
          }

          p {
            margin: 0.2rem 0;
            font-size: 0.9rem;
            color: #555;
          }

          .rating {
            font-size: 1.2rem;
            color: black;
          }
        }
      }

      .comment {
        font-size: 1.2rem;
        font-style: italic;
        text-align: left;
      }
    }

    .swiper-pagination {
      position: absolute;
      bottom: 0.5rem;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      z-index: 5;
    }

    .swiper-pagination-bullet {
      background-color: white;
      opacity: 0.4;
    }

    .swiper-pagination-bullet-active {
      background-color: var(--analytics-violet);
      opacity: 1;
    }
  }

  @media (min-width: 768px) {
    .section-title {
      font-size: 2.5rem;
    }

    .feedback-card .comment {
      font-size: 1.4rem;
    }
  }

  @media (min-width: 1024px) {
    .section-title {
      font-size: 2.8rem;
    }

    .feedback-card .comment {
      font-size: 1.6rem;
    }
  }
}
