.detail-card {
  width: 100%;

  margin: 4px 0;
  padding: 8px 16px;

  box-sizing: border-box;
  border-radius: 25px;

  background-color: var(--analytics-card-background);

  &__extra-component {
    margin-bottom: 16px;
  }

  &__header {
    position: relative; // update wytopia v0.69.0
    display: flex;
    align-items: center;
    justify-content: center; // update wytopia v0.69.0
    height: 36px;
  }

  &__icon-container--left,
  &__icon-container--right {
    position: absolute; // update wytopia v0.69.0
    top: 50%; // update wytopia v0.69.0
    transform: translateY(-50%); // update wytopia v0.69.0
    display: flex;
    align-items: center;
    column-gap: 8px;

    max-width: 30%; // update wytopia v0.69.0
    z-index: 10; // update wytopia v0.69.0
  }

  &__icon-container--left {
    left: 0; // update wytopia v0.69.0
    justify-content: flex-start;
  }

  &__icon-container--right {
    right: 0; // update wytopia v0.69.0
    justify-content: flex-end;
  }

  &__title {
    max-width: 70%; // update wytopia v0.69.0
    margin: 0 auto;
    text-align: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    color: black;
    font-weight: bold;
  }

  &__subtitle {
    align-self: flex-start;
    margin: 16px 0;

    color: var(--analytics-violet);
    font-size: 1rem;
  }

  &__content {
    margin: 10px;

    color: black;

    &--graph {
      margin: -40px -12px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .icon--small {
    width: 16px;
    height: 16px;
  }

  .icon--medium {
    width: 24px;
    height: 24px;
  }

  .icon--big {
    width: 32px;
    height: 32px;
  }
}

@media (max-width: 980px) {
  .detail-card {
    &__icon-container--left,
    &__icon-container--right {
      max-width: 20%; // update wytopia v0.69.0
    }

    .detail-card__icon {
      max-width: 100%;
      max-height: 100%;
    }
  }
}
